import styled from 'styled-components';

const Form = ({ children, title, ...rest }) => {
  return (
    <Form.Container {...rest}>
      {title && <Form.Title>{title}</Form.Title>}
      {children}
    </Form.Container>
  );
};

export default Form;

Form.Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 420px;

  @media only screen and (max-width: 460px) {
    padding: 0 20px;
  }
`;

Form.Title = styled.h5`
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.82px;
  margin: 0 0 18px 0;
`;
