import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../components/StripeElement';
import Button from '../components/shared/button';
import usePaymentStatus from '../hooks/usePaymentStatus';
import styled from 'styled-components';
import { Stepper } from './RegisterPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LogoSection = () => {
  return (
    <LogoContainer>
      <Logo src="/logo.svg" />
    </LogoContainer>
  );
};

const HeaderSection = () => {
  return (
    <HeaderContainer>
      <Header>
        Produkt-Registrierung: <br />
        Mein Ladepunkt
      </Header>
    </HeaderContainer>
  );
};

const PaymentStatus = () => {
  const { status, message } = usePaymentStatus();

  const get = () => {
    if (status === 'succeeded') {
      return (
        <>
          <PaymentStatus.Text>{message}</PaymentStatus.Text>
          <PaymentStatus.SubText>
            Sie erhalten von uns eine Email bezüglich Ihres Passwortes.
          </PaymentStatus.SubText>
        </>
      );
    } else if (status === 'processing') {
      return <PaymentStatus.Text>{message}</PaymentStatus.Text>;
    } else {
      return (
        <>
          <PaymentStatus.Text>{message}</PaymentStatus.Text>
          {status && status !== 'succeeded' && status !== 'processing' && <Button>Retry</Button>}
        </>
      );
    }
  };

  return (
    <PaymentStatus.Container>
      {!status && (
        <PaymentStatus.LoadingWrapper>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin btn-spinner large" />
        </PaymentStatus.LoadingWrapper>
      )}
      {status && get()}
    </PaymentStatus.Container>
  );
};

PaymentStatus.Container = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  max-width: 600px;

  /* @media only screen and (max-width: 460px) {
    padding: 0 20px;
  } */
  word-wrap: break-word;
`;

PaymentStatus.Text = styled.span`
  font-size: 2rem;
  font-weight: 600;
  color: #2ea836;
  margin-bottom: 5px;
`;

PaymentStatus.SubText = styled.span`
  align-self: flex-start;
`;

PaymentStatus.LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PaymentStatusPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <Container>
        <LogoSection />
        <Stepper page={6} maxPage={6} />
        <HeaderSection />
        <PaymentStatus />
      </Container>
    </Elements>
  );
};

export default PaymentStatusPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 811px;
  margin: 75px auto;

  @media only screen and (max-width: 800px) {
    margin: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img``;

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Header = styled.h1`
  color: #525252;
  font-size: 36px;
  line-height: 49px;
  font-weight: 700;
  margin: 0;
`;
