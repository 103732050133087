import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../components/shared/input';
import Button from '../components/shared/button';
import Form from '../components/shared/form';
import Checkbox from '../components/shared/checkbox';
import Relationship from '../components/Relationship';
import StripeElement from '../components/StripeElement';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUser,
  setPersonalDetails,
  setLocationDetails,
  setParkingSpotDetails,
} from '../state/slices/userSlice';
import axios from 'axios';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { anrede } from '../data/anrede';

const LogoSection = () => {
  return (
    <LogoContainer>
      <Logo src="/logo.png" />
    </LogoContainer>
  );
};

const HeaderSection = () => {
  return (
    <HeaderContainer>
      <Header>
        SYNVIA
        <br />
        Mein Ladepunkt
      </Header>
    </HeaderContainer>
  );
};

const RelationshipForm = ({ setPage }) => {
  const [no, setNo] = useState(false);

  return (
    <RelationshipForm.Container>
      {no && (
        <div style={{ padding: '0 20px' }}>
          <RelationshipForm.Text>
            Es tut uns leid, aber um sich für den E-Mobility Betriebsservice zu
            registrieren, müssen Sie Mieter der Deutsche Wohnen sein und den
            E-Mobility Bereitstellungsvertrag zur Anmietung einer Ladestation
            abgeschlossen haben.
          </RelationshipForm.Text>
          <Button
            onClick={() => {
              setNo(false);
            }}
          >
            Zurück
          </Button>
        </div>
      )}
      {!no && (
        <Form title={'E-Mobility Bereitstellungsvertrag mit Deutsche Wohnen:'}>
          <Relationship
            onYes={() => setPage((page) => page + 1)}
            onNo={() => setNo(true)}
          />
        </Form>
      )}
    </RelationshipForm.Container>
  );
};

const PersonalDetailsForm = ({ setPage }) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup
      .string('Geben Sie Ihre Emailaddresse ein')
      .email('Geben Sie eine bestehende Emailaddresse ein')
      .test('checkEmailExists', 'Die Emailaddresse ist bereits bei uns registriert', (value) => {
        return new Promise((resolve, _) => {
          axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/customer/${value}/emailExists`,
          })
            .then((res) => {
              const { exists } = res.data;

              resolve(!Boolean(exists));
            })
            .catch((e) => resolve(false));
        });
      })
      .required('Emailaddresse wird benötigt'),
    formOfAddress: yup.string('Enter your form of address').required('Anrede wird benötigt'),
    title: yup.string('Enter your title').optional(),
    firstName: yup.string('Enter your first name').required('Vorname wird benötigt'),
    lastName: yup.string('Enter your last name').required('Nachname wird benötigt'),
    phoneNumber: yup
      .string('Enter your phone number')
      .test('checkPhoneNumberExists', 'Die Telefonnummer ist bereits bei uns registriert', (value) => {
        return new Promise((resolve, _) => {
          axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_URL}/customer/${value}/phoneNumberExists`,
          })
            .then((res) => {
              const { exists } = res.data;

              resolve(!Boolean(exists));
            })
            .catch((e) => resolve(false));
        });
      })
      .required('Telefonnummer wird benötigt'),
  });

  const formik = useFormik({
    initialValues: {
      formOfAddress: user.formOfAddress,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    onSubmit: (values) => {
      dispatch(setPersonalDetails({ ...values }));
      setPage((page) => page + 1);
    },
  });

  return (
    <Form title={'Bitte geben Sie Ihre persönlichen Daten ein:'} onSubmit={formik.handleSubmit}>
      <Row>
        <>
          <Input.Container>
            <Input.Label htmlFor="formOfAddress">Anrede</Input.Label>
            <Select
              name="formOfAddress"
              id="formOfAddress"
              value={formik.values.formOfAddress}
              onChange={formik.handleChange}
              error={formik.errors.formOfAddress && formik.touched.formOfAddress}
              errorMessage={formik.errors.formOfAddress}
            >
              <Option key={-1} value={''} disabled />
              {anrede.map((asset, i) => (
                <Option key={i} value={asset}>
                  {asset}
                </Option>
              ))}
            </Select>
          </Input.Container>
          {formik.errors.formOfAddress && formik.touched.formOfAddress && (
            <Select.ErrorMessage>{formik.errors.formOfAddress}</Select.ErrorMessage>
          )}
        </>

        <Input
          id={'title'}
          label={'Titel'}
          placeholder={'Dr'}
          value={formik.values.title}
          onChange={formik.handleChange}
          error={formik.errors.title && formik.touched.title}
          errorMessage={formik.errors.title}
        />
      </Row>
      <Row>
        <Input
          id={'firstName'}
          label={'Vorname'}
          placeholder={'Max'}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.errors.firstName && formik.touched.firstName}
          errorMessage={formik.errors.firstName}
        />
        <Input
          id={'lastName'}
          label={'Nachname'}
          placeholder={'Mustermann'}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.errors.lastName && formik.touched.lastName}
          errorMessage={formik.errors.lastName}
        />
      </Row>
      <Row>
        <Input
          id={'email'}
          label={'E-Mail Adresse'}
          placeholder={'mail@abc.com'}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email && formik.touched.email}
          errorMessage={formik.errors.email}
        />
      </Row>
      <Row>
        <Input
          id={'phoneNumber'}
          label={'Telefonnummer'}
          placeholder={'+492111234567'}
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          error={formik.errors.phoneNumber && formik.touched.phoneNumber}
          errorMessage={formik.errors.phoneNumber}
        />
      </Row>
      <Row>
        <BackButton onClick={() => setPage((page) => page - 1)}>Zurück</BackButton>
        <Button loading={formik.isSubmitting}>Weiter</Button>
      </Row>
    </Form>
  );
};

const LocationDetailsForm = ({ setPage }) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [parkingSpot, setParkingSpot] = useState(user.parkingSpotAddress.enabled);

  const validationSchema = yup.object({
    'address-line-1': yup.string('Enter your address').required('Adresse Zeile 1 wird benötigt'),
    'address-line-2': yup.string().optional(),
    city: yup.string('Enter your first name').required('Ort wird benötigt'),
    postalCode: yup
      .string('Enter your postal code')
      .required('Postleitzahl wird benötigt')
      .matches(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/, 'Postleitzahl nicht gültig'),
  });

  const formik = useFormik({
    initialValues: {
      'address-line-1': user.address.line1,
      'address-line-2': user.address.line2,
      city: user.address.city,
      postalCode: user.address.postalCode,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        line1: values['address-line-1'],
        line2: values['address-line-2'],
        city: values['city'],
        postalCode: values['postalCode'],
        parkingSpot: parkingSpot,
      };
      dispatch(setLocationDetails({ ...payload }));
      setPage((page) => (parkingSpot ? page + 1 : page + 2));
    },
  });

  return (
    <Form title={'Bitte geben Sie Ihre Adresse ein:'} onSubmit={formik.handleSubmit}>
      <Row>
        <Input
          id={'address-line-1'}
          label={'Straße und Hausnummer'}
          placeholder={'Willy-Brandt-Straße 1'}
          value={formik.values['address-line-1']}
          onChange={formik.handleChange}
          error={formik.errors['address-line-1'] && formik.touched['address-line-1']}
          errorMessage={formik.errors['address-line-1']}
        />
      </Row>
      <Row>
        <Input
          id={'address-line-2'}
          label={'Optionale Adresszeile'}
          value={formik.values['address-line-2']}
          onChange={formik.handleChange}
          error={formik.errors['address-line-2'] && formik.touched['address-line-2']}
          errorMessage={formik.errors['address-line-2']}
        />
      </Row>
      <Row>
        <Input
          id={'postalCode'}
          label={'Postleitzahl'}
          placeholder={'10557'}
          value={formik.values.postalCode}
          onChange={formik.handleChange}
          error={formik.errors.postalCode && formik.touched.postalCode}
          errorMessage={formik.errors.postalCode}
        />
        <Input
          id={'city'}
          label={'Ort'}
          placeholder={'Berlin'}
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.errors.city && formik.touched.city}
          errorMessage={formik.errors.city}
        />
      </Row>
      <CheckboxContainer>
        <CheckboxLine>
          <Checkbox
            defaultChecked={parkingSpot}
            id={'parkingSpot'}
            onChange={() => setParkingSpot(!parkingSpot)}
          >
            <span>
              Ich habe bereits einen E-Mobility Bereitstellungsvertrag mit
              Deutsche Wohnen geschlossen.
            </span>
          </Checkbox>
        </CheckboxLine>
      </CheckboxContainer>
      <Row>
        <BackButton onClick={() => setPage((page) => page - 1)}>Zurück</BackButton>
        <Button loading={formik.isSubmitting}>Weiter</Button>
      </Row>
    </Form>
  );
};

const ParkingSpotLocationDetailsForm = ({ setPage }) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    pitchNumber: yup.string('Enter your pitch number').required('Parkplatznummer wird benötigt'),
    city: yup.string('Enter the location of your parking spot').required('Ort wird benötigt'),
    street: yup.string('Enter the street of your parking spot').required('Strasse wird benötigt'),
    postalCode: yup
    .string('Enter your postal code')
    .required('Postleitzahl wird benötigt')
    .matches(/^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/, 'Postleitzahl nicht gültig')
  });

  const formik = useFormik({
    initialValues: {
      pitchNumber: user.parkingSpotAddress.pitchNumber,
      city: user.parkingSpotAddress.city,
      postalCode: user.parkingSpotAddress.postalCode,
      street: user.parkingSpotAddress.street,
    },
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        pitchNumber: values['pitchNumber'],
        city: values['city'],
        street: values['street'],
        postalCode: values['postalCode'],
      };

      dispatch(setParkingSpotDetails({ ...payload }));
      setPage((page) => page + 1);
    },
  });

  return (
    <Form
      // Enter location details for your parking spot:
      title={'Bitte geben Sie die Standortdetails für Ihren Parkplatz ein:'}
      onSubmit={formik.handleSubmit}
    >
      <Row>
        <Input
          id={'street'}
          label={'Strasse des Stellplatzes'}
          placeholder={'Willy-Brandt-Straße'}
          value={formik.values.street}
          onChange={formik.handleChange}
          error={formik.errors.street && formik.touched.street}
          errorMessage={formik.errors.street}
        />
      </Row>
      <Row>
        <Input
          id={'postalCode'}
          label={'Postleitzahl'}
          placeholder={'20457'}
          value={formik.values.postalCode}
          onChange={formik.handleChange}
          error={formik.errors.postalCode && formik.touched.postalCode}
          errorMessage={formik.errors.postalCode}
        />
      </Row>
      <Row>
        <Input
          id={'city'}
          label={'Ort'}
          placeholder={'Berlin'}
          value={formik.values.city}
          onChange={formik.handleChange}
          error={formik.errors['city'] && formik.touched['city']}
          errorMessage={formik.errors['city']}
        />
      </Row>
      <Row>
        <Input
          id={'pitchNumber'}
          label={'Stellplatznummer'}
          placeholder={'10L'}
          value={formik.values.pitchNumber}
          onChange={formik.handleChange}
          error={formik.errors.pitchNumber && formik.touched.pitchNumber}
          errorMessage={formik.errors.pitchNumber}
        />
      </Row>
      <Row>
        <BackButton onClick={() => setPage((page) => page - 1)}>Zurück</BackButton>
        <Button loading={formik.isSubmitting}>Weiter</Button>
      </Row>
    </Form>
  );
};

const TermsOfUseForm = ({ setPage }) => {
  const user = useSelector(getUser);

  const validationSchema = yup.object({
    // landlord: yup.string('Select your landlord').required('Vermieter wird benötigt'),
    'terms': yup
      .boolean()
      .required('Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren')
      .oneOf([true], 'Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren'),
  });

  const formik = useFormik({
    initialValues: {
      // landlord: user.landlord ?? '',
      'terms': false,
    },
    validationSchema,
    onSubmit: (values) => {
      // const payload = {
      //   landlord: values.landlord,
      // };

      // dispatch(setLandlord({ ...payload }));

      setPage((page) => page + 1);
    },
  });

  return (
    <Form title={'Allgemeine Geschäftsbedingungen:'} onSubmit={formik.handleSubmit}>
      {/* <Row>
        <Input.Container>
          <Input.Label htmlFor="landlord">Vermieter:</Input.Label>
          <Select
            name="landlord"
            id="landlord"
            value={formik.values.landlord}
            onChange={formik.handleChange}
            error={formik.errors.landlord && formik.touched.landlord}
            errorMessage={formik.errors.landlord}
          >
            <Option key={-1} value={''} disabled>
              Wählen Sie Ihren Vermieter...
            </Option>
            {assets.map((asset, i) => (
              <Option key={i} value={asset.name}>
                {asset.name}
              </Option>
            ))}
          </Select>
        </Input.Container>
        {formik.errors.landlord && formik.touched.landlord && (
          <Select.ErrorMessage>{formik.errors.landlord}</Select.ErrorMessage>
        )}
      </Row> */}

      {/* formik.values.landlord && */(
        <CheckboxContainer>
          <CheckboxLine>
            <Checkbox
              id={'terms'}
              value={formik.values['terms']}
              onChange={formik.handleChange}
              error={formik.errors['terms'] && formik.touched['terms']}
              errorMessage={formik.errors['terms']}
            >
              <span>
                Hiermit akzeptiere ich die allgemeinen{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`https://storage.googleapis.com/getec-terms-of-use/Plattform%20Basis_190422.pdf`}
                >
                  Geschäftsbedingungen
                </a>{' '}
                des E-Mobility Betriebsservice.
              </span>
            </Checkbox>
          </CheckboxLine>
        </CheckboxContainer>
      )}
      <Row>
        <BackButton
          onClick={() =>
            setPage((page) => {
              if (user.parkingSpotAddress.enabled) {
                return page - 1;
              } else {
                return page - 2;
              }
            })
          }
        >
          Zurück
        </BackButton>
        <Button loading={formik.isSubmitting}>Weiter</Button>
      </Row>
    </Form>
  );
};

RelationshipForm.Container = styled.div`
  max-width: 420px;
  width: 100%;
`;

RelationshipForm.Text = styled.span`
  font-size: 26px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
  display: block;
  color: #2ea836;
`;

const StripeSetupIntentForm = ({ setPage }) => {
  const user = useSelector(getUser);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const payloadUser = {
      formOfAddress: user.formOfAddress,
      title: user.title,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      address: {
        line1: user.address.line1,
        line2: user.address.line2,
        city: user.address.city,
        postalCode: user.address.postalCode,
      },
      // landlord: assets.find((asset) => asset.name === user.landlord),
    };

    if (user.parkingSpotAddress.enabled) {
      payloadUser.parkingSpotAddress = {
        pitchNumber: user.parkingSpotAddress.pitchNumber,
        city: user.parkingSpotAddress.city,
        postalCode: user.parkingSpotAddress.postalCode,
        street: user.parkingSpotAddress.street,
      };
    }

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/stripe`,
      headers: { 'Content-Type': 'application/json' },
      data: payloadUser,
    })
      .then((res) => {
        setClientSecret(res.data.clientSecret);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <>
      {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin btn-spinner large" />}
      {clientSecret && <StripeElement setLoading={setLoading} clientSecret={clientSecret} />}
    </>
  );
};

const RegisterPage = () => {
  const [page, setPage] = useState(0);

  return (
    <Container>
      <LogoSection />
      <Stepper page={page} maxPage={6} />
      <HeaderSection />
      {page === 0 && <RelationshipForm setPage={setPage} />}
      {page === 1 && <PersonalDetailsForm setPage={setPage} />}
      {page === 2 && <LocationDetailsForm setPage={setPage} />}
      {page === 3 && <ParkingSpotLocationDetailsForm setPage={setPage} />}
      {page === 4 && <TermsOfUseForm setPage={setPage} />}
      {page === 5 && <StripeSetupIntentForm setPage={setPage} />}
    </Container>
  );
};

export const Stepper = ({ page, maxPage }) => {
  const get = () => {
    const elems = [];
    for (let i = 0; i <= maxPage; i++) {
      elems.push(
        <>
          <div className="step-title" active={i <= page}>
            <StepperTitleNumber className="step-title-number" active={i <= page} />
          </div>
          {i < maxPage && (
            <StepperProgressBar className="stepper-progress-bar" active={i <= page} />
          )}
        </>,
      );
    }

    return elems;
  };

  return (
    <StepperWrapper>
      <div className="stepper-wrapper">
        <div className="stepper-progress">
          <div className="stepper-progress-wrapper">{get()}</div>
        </div>
      </div>
    </StepperWrapper>
  );
};

const StepperWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 13px;

  .step-wrapper {
    width: 600px;
    height: 100%;
  }

  .stepper-selector {
    position: absolute;
    top: 0;
    height: 100%;
    display: inline-flex;
    /* Animate each step movement */
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  }

  .stepper-progress {
    display: flex;
    justify-content: center;
  }

  .stepper-progress-wrapper {
    width: 90%;
    position: relative;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .step-title {
    text-align: center;
    font-size: 0.7rem;
    align-items: center;
    background: #fff;
    /* padding: 0 1rem; */
    /* height: 30px; */
  }
`;

const StepperProgressBar = styled.div`
  width: 10%;
  max-width: 40px;
  height: 1px;
  top: 12px;
  z-index: -1;
  background: ${(props) => (props.active ? '#2ea836; ' : '#B9B9C3')};
  transition: width 1s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  margin: 0 2px;
`;

const StepperTitleNumber = styled.div`
  font-size: 1rem;
  background: ${(props) => (props.active ? '#2ea836' : '#fff')};
  height: 12px;
  width: 12px;
  margin: 0.3rem auto;
  line-height: 1.5;
  border: ${(props) => (props.active ? 'none' : '1px solid #B9B9C3')};
  border-radius: 100%;
`;

export default RegisterPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 811px;
  margin: 75px auto 184px auto;
  position: relative;

  @media only screen and (max-width: 800px) {
    margin: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 15px 0;
`;

const Logo = styled.img`
  width: 80px;
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Header = styled.h1`
  color: #525252;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 24px;
  gap: 20px;
  position: relative;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
`;

const CheckboxLine = styled.div`
  margin: 6px 0;
  display: flex;
  align-items: flex-start;
`;

const Select = styled.select`
  border: 1px solid #ded2d9;
  border-radius: 5px;
  color: #525252;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  padding: 13px 10px;
  height: 45px;
  width: 100%;
  background-color: #fff;

  :focus {
    outline: none;
    border: 1px solid #2ea836;
  }

  ${(props) => {
    if (props.error) {
      return `
        border: 1px solid red;
      `;
    }
  }}
`;

const Option = styled.option``;

Select.ErrorMessage = styled.span`
  position: absolute;
  top: 68px;
  left: 0px;
  font-size: 10px;
  color: red;
`;

const BackButton = styled(Button)`
  background-color: #525252;
  opacity: 0.7;
`;
