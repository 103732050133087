import { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const usePaymentStatus = () => {
  const stripe = useStripe();
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret',
    );

    if (!clientSecret) {
      navigate('/');
      return;
    }

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent.status) {
        case 'succeeded':
          setStatus(setupIntent.status);
          setMessage('Erfolg! Ihre Zahlungsmethode wurde gespeichert.');
          break;

        case 'processing':
          setStatus(setupIntent.status);
          setMessage(
            'Ihre Zahlungsmethode wird erstellt. Wir schicken Ihnen eine Email sobald sie abgeschlossen ist.',
          );
          break;

        case 'requires_payment_method':
          setStatus(setupIntent.status);
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage(
            'Ihre Zahlungsmethode konnte nicht erstellt werden. Bitte versuchen Sie es später erneut oder melden Sie sich bei unserem Kundenddienst.',
          );
          break;
        default:
          break;
      }
    });
  }, [stripe]);

  return { status, message };
};

export default usePaymentStatus;
