import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PaymentStatusPage from './pages/PaymentStatusPage';
import RegisterPage from './pages/RegisterPage';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        {/* DEFAULT ROUTE */}
        <Route exact path={'/'} element={<RegisterPage />} />
        <Route exact path={'/setup-intent-status'} element={<PaymentStatusPage />} />
      </Routes>
    </BrowserRouter>
  );
}
