import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  formOfAddress: '',
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    postalCode: '',
  },
  parkingSpotAddress: {
    enabled: false,
    postalCode: '',
    pitchNumber: '',
    city: '',
    street: '',
  },
  // landlord: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPersonalDetails: (state, { payload }) => {
      const { formOfAddress, title, firstName, lastName, email, phoneNumber } =
        payload;
      state.formOfAddress = formOfAddress;
      state.title = title;
      state.firstName = firstName;
      state.lastName = lastName;
      state.email = email;
      state.phoneNumber = phoneNumber;
    },
    setLocationDetails: (state, { payload }) => {
      const { parkingSpot, line1, line2, city, postalCode } = payload;
      state.address = {
        line1,
        line2,
        city,
        postalCode,
      };
      state.parkingSpotAddress.enabled = parkingSpot;
    },
    setParkingSpotDetails: (state, { payload }) => {
      const { pitchNumber, city, street, postalCode } = payload;
      state.parkingSpotAddress = {
        enabled: state.parkingSpotAddress.enabled,
        pitchNumber,
        city,
        street,
        postalCode
      };
    },
    // setLandlord: (state, { payload }) => {
    //   const { landlord } = payload;
    //   state.landlord = landlord;
    // },
  },
});

export const getUser = (state) => state.user;
export const getCustomerId = (state) => state.user.customer_id;
export const {
  setPersonalDetails,
  setLocationDetails,
  setParkingSpotDetails, 
  /* setLandlord, */
} = userSlice.actions;

export default userSlice.reducer;
