import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeForm from './StripeForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const appearance = {
  theme: 'stripe',
  labels: 'floating',

  variables: {
    colorPrimary: '#34834f',
    colorBackground: '#fff',
    colorText: 'rgba(0, 0, 0, 0.87)',
    colorDanger: '#d32f2f',
    fontFamily: 'Roboto, system-ui, sans-serif',
    spacingUnit: '2px',

    borderRadius: '4px',
    // #34834f
    // spacingTab: '16px',
    spacingGridRow: '16px',
    spacingGridColumn: '16px',
    // See all possible variables below
  },
};

const StripeElement = ({ setLoading, clientSecret }) => {
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm setLoading={setLoading} />
    </Elements>
  );
};

export default StripeElement;
