import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Button from './shared/button';

const StripeForm = ({ setLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      // setErrorMessage(error.message);
      setSubmitting(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        onReady={() => {
          setLoading(false);
          setLoaded(true);
        }}
      />
      {loaded && (
        <SubmitButton disabled={!stripe || !loaded} loading={submitting}>
          Weiter
        </SubmitButton>
      )}

      {/* Show error message to your customers */}
      {/* {errorMessage && <div>{errorMessage}</div>} */}
    </Form>
  );
};

export default StripeForm;

const Form = styled.form`
  max-width: 420px;
  width: 100%;

  @media only screen and (max-width: 460px) {
    padding: 20px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 32px;

  :active {
  }
`;
