import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Button = ({ children, onBtnClick, loading, ...rest }) => {
  // const handleOnClick = (e) => {
  //   e.preventDefault();

  //   if (onBtnClick) onBtnClick(e);
  // };

  return (
    <Button.Button disabled={loading} type="submit" {...rest}>
      <span>
        {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin btn-spinner" />}
        {!loading && <span>{children}</span>}
      </span>
    </Button.Button>
  );
};

export default Button;

Button.Button = styled.button`
  background-color: #2ea836;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  width: 100%;
  min-height: 50px;
  padding: 13px 0;

  ${(props) => {
    if (props.disabled) {
      return `
        cursor: not-allowed;
      `;
    }
  }}

  .btn-spinner {
    width: 22px;
    height: 22px;
  }
`;
