import styled from 'styled-components';

const Yes = ({ onBtnClick, onHover, ...rest }) => {
  return (
    <Relationship.Container {...rest}>
      <Relationship.Icon src="/pent-1.svg" />
      <Relationship.TextWrapper>
        <Relationship.Title>Ja</Relationship.Title>
        <Relationship.Subtitle>
          Ich habe bereits den Vertrag zur Anmietung einer Ladestation mit meiner Vermietung geschlossen.
        </Relationship.Subtitle>
      </Relationship.TextWrapper>
      <Relationship.Arrow src="/arrow-right.svg" className="arrow" />
    </Relationship.Container>
  );
};

const No = ({ onBtnClick, onHover, ...rest }) => {
  return (
    <Relationship.Container {...rest}>
      <Relationship.Icon src="/pent-2.svg" />
      <Relationship.TextWrapper>
        <Relationship.Title>Nein</Relationship.Title>
        <Relationship.Subtitle>
          Ich habe noch keinen Vertrag zur Anmietung einer Ladestation geschlossen.
        </Relationship.Subtitle>
      </Relationship.TextWrapper>
      <Relationship.Arrow src="/arrow-right.svg" className="arrow" />
    </Relationship.Container>
  );
};

const Relationship = ({ onYes, onNo }) => {
  return (
    <>
      <Yes active onClick={onYes} />
      <No onClick={onNo} />
    </>
  );
};

export default Relationship;

Relationship.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  width: 100%;
  min-height: 90px;
  height: 100%;
  box-sizing: content-box;
  margin-bottom: 28px;
  user-select: none;
  cursor: pointer;

  .arrow {
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }

  ${(props) => {
    if (props.active) {
      return `
        background: #f0f6ec;
        border: 1px solid #2ea836;
        box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);

        .arrow {
          visibility: visible;
        }
    `;
    }
  }}

  :hover {
    background: #f0f6ec;
    border: 1px solid #2ea836;
    box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.06);

    .arrow {
      visibility: visible;
    }
  }

  :focus {
    background: #f0f6ec;
    border: 1px solid #2ea836;
    box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);

    .arrow {
      visibility: visible;
    }
  }
`;

Relationship.Icon = styled.img`
  padding: 0 13px 0px 10px;

  /* @media only screen and (max-width: 460px) {
    display: none;
  } */
`;

Relationship.TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 460px) {
    padding: 10px;
  }
`;

Relationship.Title = styled.span`
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #000;
  margin-bottom: 3px;
`;

Relationship.Subtitle = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #828282;
`;

Relationship.Arrow = styled.img`
  margin-right: 10px;
`;
