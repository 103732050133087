import styled from 'styled-components';

const Checkbox = ({ id, name, children, error, errorMessage, ...rest }) => {
  return (
    <Checkbox.Container>
      <Checkbox.Text>{children}</Checkbox.Text>
      <Checkbox.Input error={error} type="checkbox" id={id} name={name} {...rest} />
      <Checkbox.ErrorMessage>{error && errorMessage}</Checkbox.ErrorMessage>
    </Checkbox.Container>
  );
};

export default Checkbox;

Checkbox.Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 5px;
  position: relative;
`;

Checkbox.Text = styled.span`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.37px;
  color: #a1a1a1;
  max-width: calc(100% - 12px);
  word-wrap: break-word;
`;

Checkbox.Input = styled.input`
  display: inline-block;
  appearance: none;
  background: #ffffff;
  /* border: 1px solid #dadada; */
  box-sizing: border-box;
  border-radius: 2px;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  padding: 0;
  margin: 0;
  user-select: none;
  cursor: pointer;

  :checked {
    /* background-color: #2ea836;
    border: none; */

    :before {
      visibility: visible;
    }

    :after {
      visibility: hidden;
    }
  }

  :before {
    position: absolute;
    content: '';
    top: -1.5px;
    right: -1.5px;
    background-image: url('/check-square.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    visibility: hidden;
  }

  :after {
    position: absolute;
    content: '';
    top: -1.5px;
    right: -1.5px;
    background-image: url('/square.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
  }
`;

Checkbox.ErrorMessage = styled.span`
  position: absolute;
  bottom: -12px;
  font-size: 10px;
  color: red;
`;
