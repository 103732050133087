import styled from 'styled-components';

const Input = ({ id, placeholder, label, autoComplete, options, error, errorMessage, ...rest }) => {
  return (
    <Input.Container>
      <Input.Label for={id}>{label}</Input.Label>
      <Input.InputWrapper>
        <Input.Input
          placeholder={placeholder}
          type="text"
          id={id}
          name={id}
          auto-complete={autoComplete}
          error={error ?? false}
          errorMessage={errorMessage}
          {...rest}
        />
      </Input.InputWrapper>
      <Input.ErrorMessage>{error && errorMessage}</Input.ErrorMessage>
    </Input.Container>
  );
};

export default Input;

Input.Container = styled.div`
  position: relative;
  width: 100%;

  ${(props) => {
    if (props.first || props.second) {
      return `
      width: unset;
      display: flex;
      flex-direction: column;
    `;
    }
  }}

  ${(props) => {
    if (props.first) {
      return `
      align-items: flex-start ;
    `;
    } else if (props.second) {
      return `
      align-items: flex-end;

      label {
        margin-left: 10px;
      }
    `;
    }
  }}
`;

Input.Label = styled.label`
  color: #828282;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.1px;
  margin-bottom: 4px;
  align-self: flex-start;
  display: block;
`;

Input.InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 45px;
  max-width: 100%;

  ${(props) => {
    if (props.half) {
      return `
      max-width: calc(100% - 10px);
    `;
    }
  }}
`;

Input.Input = styled.input`
  border: 1px solid #ded2d9;
  border-radius: 5px;
  color: #525252;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.1px;
  padding: 13px 10px;
  height: 100%;
  width: 100%;

  ${(props) => {
    if (props.error) {
      return `
        border: 1px solid red;
      `;
    }
  }}

  :focus {
    outline: none;
    ${(props) => {
      if (!props.error) {
        return `
        border: 1px solid #2ea836;
      `;
      }
    }}
  }

  ::placeholder {
    color: #e0e0e0;
    opacity: 1;
  }
`;

Input.ErrorMessage = styled.span`
  position: absolute;
  top: 68px;
  font-size: 10px;
  color: red;
`;
